export function createHeaders() {
  return {
    0: {
      vals: [
        "card_name",
        "shop_name",
        "app_type_shop_type_flag",
        "card_code",
        "statuses",
        "binding_member_mobile",
        "update_time",
        "card_value",
        "left_value",
        "client_name",
        "company",
        "active_salesman",
        "sell_trade_sn",
      ],
      headers: [
        "卡券名称",
        "所属商城",
        "应用类型",
        "卡号",
        "卡号状态",
        "绑定手机号",
        "操作日期",
        "面值",
        "余额",
        "客户名称",
        "公司名称",
        "销售人员",
        "关联销售单号",
      ],
    },
    1: {
      vals: [
        "card_name",
        "shop_name",
        "app_type_shop_type_flag",
        "card_code",
        "card_value",
        "statuses",
        "exchange_items",
        "exchange_time",
        "client_name",
        "company",
        "active_salesman",
        "sell_trade_sn",
      ],

      headers: [
        "卡券名称",
        "所属商城",
        "应用类型",
        "卡号",
        "价位",
        "卡号状态",
        "兑换礼包",
        "兑换时间",
        "客户名称",
        "公司名称",
        "销售人员",
        "关联销售单号",
      ],
    },
  };
}

export function createDefaultItem() {
  return {
    shop_id: "",
    shop_name: "",
    card_id: "",
    card_name:"",
    rowsapn: [1, 1, 1],
    card_name: "",
    card_value: "",
    card_code_start: "",
    card_code_end: "",
    card_num: 0,
    discount_ratio: 100,
    discount_value: "",
    after_discount_price: "",
    unify_discount_rate: 100,
    unify_discount_rate_status: false,
    disabledCardNum: false,
    merge_card_num:0,
    merge_discount_value:"",
    merge_after_discount_price:"",
    trade_sn:"",
  };
}
