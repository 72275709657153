<template>
  <el-form-item :label="title" class="card-info">
    <el-table :data="cardList" :span-method="spanMethod" border>
      <el-table-column
        label="关联销售订单号"
        width="210"
        v-if="!filterColumn.includes('关联销售订单号')"
      >
        <template slot-scope="{ row, $index }">
          <div class="flex-box">
            <el-select
              size="small"
              v-model="row.trade_sn"
              filterable
              @change="handleChangeTradeSn(row, $index)"
              @focus="handleTradeSnFocus"
              :disabled="disabledColumn.includes('关联销售订单号')"
            >
              <el-option
                v-for="(item, index) in tradeSnList"
                :key="index"
                :value="item.value"
                :label="item.value"
                :disabled="filterOption(row, 'trade_sn', item.value)"
              />
            </el-select>
            <el-button
              class="icon-btn ar-btn"
              icon="el-icon-circle-plus-outline"
              circle
              @click="addCardSection(row, $index, 'trade_sn')"
              v-if="!disabledColumn.includes('关联销售订单号')"
              :disabled="getNullNum(row, 'trade_sn')"
            ></el-button>
            <el-button
              class="icon-btn ar-btn"
              icon="el-icon-remove-outline"
              circle
              :disabled="cardList.length === 1"
              @click="delCardSection(row, $index, 'trade_sn')"
              v-if="!disabledColumn.includes('关联销售订单号')"
            ></el-button>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="所属商城"
        width="210"
        v-if="!filterColumn.includes('所属商城')"
      >
        <template slot-scope="{ row, $index }">
          <div class="flex-box">
            <el-select
              v-if="shopList.length == 0"
              size="small"
              :value="row.shop_name"
              filterable
              @change="handleChangeShopId(row, $index)"
              @focus="handleShopFocus(row.trade_sn)"
              :disabled="!row.trade_sn || disabledColumn.includes('所属商城')"
            ></el-select>
            <el-select
              v-else
              size="small"
              v-model="row.shop_name"
              filterable
              @change="handleChangeShopId(row, $index)"
              @focus="handleShopFocus(row.trade_sn)"
              :disabled="disabledColumn.includes('所属商城')"
            >
              <el-option
                v-for="(item, index) in shopList"
                :key="index"
                :value="item.shop_id"
                :label="item.shop_name"
                :disabled="filterOption(row, 'shop_id', item.shop_id)"
              />
            </el-select>
            <el-button
              class="icon-btn ar-btn"
              icon="el-icon-circle-plus-outline"
              circle
              @click="addCardSection(row, $index, 'shop_id')"
              v-if="!disabledColumn.includes('所属商城')"
              :disabled="!row.trade_sn || getNullNum(row, 'shop_id')"
            ></el-button>
            <el-button
              class="icon-btn ar-btn"
              icon="el-icon-remove-outline"
              circle
              :disabled="cardList.length === 1"
              @click="delCardSection(row, $index, 'shop_id')"
              v-if="!disabledColumn.includes('所属商城')"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="卡券名称"
        width="210"
        v-if="!filterColumn.includes('卡券名称')"
      >
        <template slot-scope="{ row, $index }">
          <div class="flex-box">
            <el-select
              v-model="row.card_name"
              filterable
              size="small"
              @focus="getShopCardList(row)"
              :disabled="disabledColumn.includes('卡券名称') || !row.shop_id"
              @change="handleChangeCardId(row, $index)"
            >
              <el-option
                v-for="(item, index) in shopCardList"
                :key="index"
                :label="item.label"
                :value="item.value"
                :disabled="checkCardName(item)"
              ></el-option>
              <!-- filterOption(row,'card_id',item.value) -->
            </el-select>

            <el-button
              class="icon-btn ar-btn"
              icon="el-icon-circle-plus-outline"
              circle
              @click="addCardSection(row, $index, 'card_id')"
              v-if="!disabledColumn.includes('卡券名称')"
              :disabled="!row.trade_sn || getNullNum(row, 'card_id')"
            ></el-button>

            <el-button
              class="icon-btn ar-btn"
              icon="el-icon-remove-outline"
              circle
              :disabled="cardList.length === 1"
              @click="delCardSection(row, $index, 'card_id')"
              v-if="!disabledColumn.includes('卡券名称')"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="面值/价位"
        width="120"
        v-if="!filterColumn.includes('面值/价位')"
      >
        <template slot-scope="{ row }">
          <el-input-number
            style="width: 100%"
            size="small"
            :controls="false"
            :min="0.0"
            :precision="2"
            v-model="row.card_value"
            :disabled="disabledColumn.includes('面值/价位')"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="卡号段"
        min-width="360"
        v-if="!filterColumn.includes('卡号段')"
      >
        <template slot-scope="{ row, $index }">
          <div class="flex-box">
            <el-input
              size="small"
              ref="cardCodeStart"
              style="min-width: 150px;"
              v-model.trim="row.card_code_start"
              :disabled="disabledColumn.includes('卡号段') || !row.card_id"
              @focus="handleSaveCardList"
              @blur="handleCardCouponRange(row, $index, 'range_start')"
            />
            -
            <el-input
              size="small"
              style="min-width: 150px;"
              v-model.trim="row.card_code_end"
              :disabled="disabledColumn.includes('卡号段') || !row.card_id"
              @focus="handleSaveCardList"
              @blur="handleCardCouponRange(row, $index, 'range_end')"
            />
            <el-button
              class="icon-btn ar-btn"
              icon="el-icon-circle-plus-outline"
              circle
              @click="addCardSection(row, $index, 'card_code')"
              v-if="!disabledColumn.includes('卡号段')"
              :disabled="!row.trade_sn || getNullNum(row, 'card_range')"
            ></el-button>

            <el-button
              class="icon-btn ar-btn"
              :disabled="cardList.length === 1"
              icon="el-icon-remove-outline"
              circle
              @click="delCardSection(row, $index, 'card_code')"
              v-if="!disabledColumn.includes('卡号段')"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="数量" v-if="!filterColumn.includes('数量')">
        <template slot-scope="{ row, $index }">
          <el-input-number
            style="width: 100%"
            :controls="false"
            :min="0"
            :precision="0"
            size="small"
            v-model.number="row.merge_card_num"
            @input="
              (item, val) => {
                if (val && val.toString().includes('-')) {
                  item.merge_card_num = 0;
                }
              }
            "
            @focus="handleSaveCardList"
            @blur="handleTableChange(row, $index, 'num')"
            :disabled="
              row.disabledCardNum ||
              disabledColumn.includes('数量') ||
              checkDisabledNum(row, $index)
            "
          />
        </template>
      </el-table-column>
    </el-table>
  </el-form-item>
</template>

<script>
import * as API_saleCard from "@/api/saleCard";
import { createDefaultItem } from "@/views/tools/sale-card-open/utils/create";

export default {
  name: "card-list",
  props: {
    title: {
      type: String,
      default: "卡券信息",
    },
    disabled: {
      default: () => {
        return ["面值/价位"];
      },
    },
    filterColumn: {
      default: () => {
        return []; // "统一折扣率设置"
      },
    },
    tradeSnData: {
      default: () => {
        return [];
      },
    },
    close_sn: {
      type: String | Number,
      default: "",
    },
    cardData: {
      default: () => [],
    },
    appType: {
      type: String | Number,
      default: "",
    },
  },
  data() {
    return {
      tradeSnList: [],
      shopList: [],
      cardList: [],
      spanArr: [],
      shopCardList: [],
      lastShop: null,
      disabledColumn: [],
      hisCardStr: [],
    };
  },
  watch: {
    cardData(v) {
      this.init();
    },
    tradeSnData(v) {
      let list = v || [];
      this.tradeSnList = list.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
    },
  },
  created() {
    if (this.disabled === true) {
      this.disabledColumn = [
        "关联销售订单号",
        "所属商城",
        "卡券名称",
        "面值/价位",
        "卡号段",
        "数量",
      ];
    } else {
      this.disabledColumn = this.disabled;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log("init", this.cardData);
      if (this.cardData.length === 0) {
        this.cardList = [createDefaultItem()];
      } else {
        this.cardList = [...this.cardData].map((item) => {
          return {
            ...createDefaultItem(),
            ...item,
          };
        });
      }
      this.emitChange();
    },
    clear() {
      this.cardList.length = 0;
      this.cardList = [createDefaultItem()];
      this.emitChange();
    },
    reset() {
      this.cardList = [...this.cardData];
      this.emitChange();
    },
    getCardData() {
      return this.groupArrayByKey(this.cardList, "trade_sn");
    },
    filterOption(row, type, value) {
      if (row[type] == value) return false;
      if (type == "trade_sn") {
        return !!this.cardList.find((item) => item["trade_sn"] == value);
      }
      if (type == "shop_id") {
        let _shop_ids = this.groupArrayByKey(this.cardList, "trade_sn")[
          row.trade_sn
        ].map((item) => item.shop_id);
        for (let i = 0; i < this.shopList.length; i++) {
          if (_shop_ids.includes(this.shopList[i].shop_id)) {
            return true;
          }
        }
        return false;
      }
      if (type == "card_id") {
        // let _list = Object.keys(this.groupArrayByKey(this.cardList, type));
        return false;
      }
      return false;
    },
    getNullNum(row, type) {
      if (type == "trade_sn") {
        let list = this.groupArrayByKey(this.cardList, type)[""] || [];
        return !!list.length;
      }
      if (type == "shop_id") {
        let list =
          this.groupArrayByKey(this.cardList, "trade_sn")[row.trade_sn] || [];
        let list2 = this.groupArrayByKey(list, "shop_id")[""] || [];
        return !!list2.length;
      }
      if (type == "card_id") {
        let list =
          this.groupArrayByKey(this.cardList, "trade_sn")[row.trade_sn] || [];
        let list2 = this.groupArrayByKey(list, "shop_id")[row.shop_id] || [];
        let list3 = this.groupArrayByKey(list2, "card_id")[""] || [];
        return !!list3.length;
      }
      if (type == "card_range") {
        let ls = this.groupArrayByKey(this.cardList, "trade_sn")[row.trade_sn] || [];
        let list =this.groupArrayByKey(ls, "shop_id")[row.shop_id] || [];
        let list2 = this.groupArrayByKey(list, "card_id")[row.card_id] || [];
        let list3 = this.groupArrayByKey(list2, "card_code_start")[""] || [];
        let list4 = this.groupArrayByKey(list2, "card_code_end")[""] || [];
        return !!list3.length || !!list4.length
      }
    },
    // 获取商城的卡券列表
    getShopCardList(row) {
      const { close_sn } = this;
      const { trade_sn, shop_id } = row;
      if (!close_sn) {
        return this.$message.error("请输入正确的关联销售关卡单号");
      }
      if (!trade_sn) {
        return this.$message.error("请选择关联销售单号");
      }
      if (!trade_sn) {
        return this.$message.error("请选择商城");
      }
      API_saleCard.getShopCardList2({
        close_sn,
        trade_sn,
        shop_id,
      }).then((res) => {
        this.shopCardList = res.map((item) => {
          return {
            value: item.card_id,
            label: item.card_name,
          };
        });
      });
    },
    handleSaveCardList() {
      let list = this.cardList.map((item) => item);
      this.hisCardStr = JSON.stringify(list);
    },
    checkList(data) {
      let _objData = this.groupArrayByKey(data, "trade_sn");
      for (const key in _objData) {
        let _data = _objData[key];
        this._checkList(_data);
      }
      // console.log(this.cardList);
    },
    _checkList(data, key = "trade_sn", index = 0) {
      let _item;
      for (let i = 0; i < data.length; i++) {
        if (!data[i].trade_sn) {
          data[i].rowsapn = [1, 1, 1];
        }

        // if (
        //   i === 0 ||
        //   (i>0 && data[i][key] == data[i - 1][key] && data[i][key] !== "")
        // ) {
        let obj = data.find((d) => d[key] === data[i][key]);
        let _list = data.filter((d) => d[key] === data[i][key]);
        obj.rowsapn[index] = _list.length;
        if (i === 0) obj.rowsapn[index] = 1;
        else if (i > 0 && data[i][key] == data[i - 1][key]) {
          data[i].rowsapn[index] = 0;
        }
        if (i === 0) obj.rowsapn[index] = 1;
        if (_list.length && _item != obj && key === "trade_sn") {
          _item = obj;
          this._checkList(_list, "shop_id", 1);
        }
        if (_list.length && _item != obj && key === "shop_id") {
          this._checkList(_list, "card_id", 2);
        }
        if (_list.length && _item != obj && key === "card_id") {
          obj["merge_card_num"] = _list
            .map((item) => +item.card_num)
            .reduce((a, b) => a + b, 0);
        }
        // } else {
        //   data[i].rowsapn[index] = 1;
        // }
      }
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (["关联销售订单号"].includes(column.label)) {
        return {
          rowspan: row.rowsapn[0],
          colspan: 1,
        };
      }
      if (["所属商城"].includes(column.label)) {
        return {
          rowspan: row.rowsapn[1],
          colspan: 1,
        };
      }
      if (["卡券名称", "面值/价位", "数量"].includes(column.label)) {
        return {
          rowspan: row.rowsapn[2],
          colspan: 1,
        };
      }
    },
    emitChange() {
      this.checkList(this.cardList);
      this.$emit("change", {
        cardList: this.cardList,
      });
    },
    roundNum(num, decimal = 10) {
      return Math.round(num * 10 ** decimal) / 10 ** decimal;
    },
    addCardSection(row, index, type) {
      let item = createDefaultItem();
      let list = [...this.cardList];
      let tab = 0;
      switch (type) {
        case "trade_sn":
          tab = list.length;
          break;
        case "shop_id":
          item.trade_sn = row.trade_sn;
          for (let i = index; i < list.length; i++) {
            if (list[i].trade_sn != row.trade_sn) {
              tab = i;
              break;
            } else tab = list.length;
          }
          break;
        case "card_id":
          item.trade_sn = row.trade_sn;
          item.shop_id = row.shop_id;
          item.shop_name = row.shop_name;
          for (let i = index; i < list.length; i++) {
            if (list[i].trade_sn != row.trade_sn || list[i].shop_id != row.shop_id) {
              tab = i;
              break;
            } else tab = list.length;
          }
          break;
        case "card_code":
          item.trade_sn = row.trade_sn;
          item.shop_id = row.shop_id;
          item.shop_name = row.shop_name;
          item.card_id = row.card_id;
          item.card_name = row.card_name;

          for (let i = index; i < list.length; i++) {
            if (
              list[i].trade_sn != row.trade_sn ||
              list[i].shop_id != row.shop_id ||
              (list[i].shop_id == row.shop_id && list[i].card_id != row.card_id)
            ) {
              tab = i;
              break;
            } else tab = list.length;
          }
        default:
          break;
      }
      this.cardList.splice(tab, 0, item);
      this.emitChange();
    },
    delCardSection(row, index, type) {
      if (row[type] === "") {
        this.cardList.splice(index, 1);
        this.emitChange();
        return;
      }
      switch (type) {
        case "trade_sn":
          this.cardList = [].filter.call(
            this.cardList,
            (item) => item.trade_sn !== row.trade_sn
          );
          break;
        case "shop_id":
          this.cardList = [].filter.call(this.cardList, (item) => {
            // console.log(item.trade_sn, row.trade_sn);
            return (
              item.trade_sn != row.trade_sn ||
              (item.trade_sn == row.trade_sn && item.shop_id !== row.shop_id)
            );
          });
          break;
        case "card_id":
          this.cardList = [].filter.call(
            this.cardList,
            (item) =>{
              return item.trade_sn != row.trade_sn ||
              (item.trade_sn == row.trade_sn && item.shop_id !== row.shop_id) ||
              (item.trade_sn == row.trade_sn &&
              item.shop_id == row.shop_id &&
              item.card_id !== row.card_id)
            }
          );
          break;
        case "card_code":
          this.cardList.splice(index, 1);
        default:
          break;
      }
      if (this.cardList.length === 0) this.addCardSection();

      this.emitChange();
    },
    // 编辑卡券信息
    handleTableChange(row, index, type) {
      this.handleCardCouponRange(row, index, type);
      // let _cards = this.groupArrayByKey(this.cardList, "shop_id");
      // let _list = _cards[row.shop_id];
      // this.$emit("range", {
      //   type,
      //   item: row,
      //   index,
      //   list: _list,
      //   cards: _cards,
      //   cardList: this.cardList,
      // });
    },
    checkDisabledNum(row, index) {
      // console.log(row);
      if (
        !row.shop_id ||
        !row.card_id ||
        !row.card_code_start ||
        !row.card_code_end
      )
        return true;
      for (let i = index; i < index + row.rowsapn[0]; i++) {
        let item = this.cardList[i];
        if (!item.card_code_start || !item.card_code_end) {
          return true;
        }
      }
      return false;
    },
    backtrack({type,item,index}) {
      console.log(type,item,index)
      let list = JSON.parse(this.hisCardStr)
      if(type=="range_end" || type=="range_start") {
        console.log(list,item,index)
      }
      this.cardList = list;
    },
    // 编辑卡号段
    handleCardCouponRange(row, $index, type) {
      let card_code_start = row.card_code_start;
      let card_code_end = row.card_code_end;
      if (!card_code_start || !card_code_end) {
        this.$message.error("卡号区间的起始、结束输入框为必填项");
        return false;
      }
      let _cards = this.groupArrayByKey(this.cardList, "trade_sn");
      let _list = _cards[row.trade_sn];
      let index = _list.findIndex(
        (item) =>
          item.card_code_start == card_code_start &&
          item.card_code_end == card_code_end
      );
      if (_list.find((item) => !item.card_code_start || !item.card_code_end)) {
        this.$message.error("卡号区间的起始、结束输入框为必填项");
        return false;
      }
      let reg = /^(?:\w{0,5})?\d+$/;
      if (!reg.test(card_code_start) || !reg.test(card_code_end)) {
        this.$message.error("卡号区间的格式有误");
        this.backtrack();
        return;
      }
      let csm = card_code_start.match(/^[a-zA-Z]+/);
      let esm = card_code_end.match(/^[a-zA-Z]+/);
      let head_start = csm ? csm[0] : "";
      let head_end = esm ? esm[0] : "";

      if (head_start != head_end) {
        this.$message.error("卡号区间的前缀有误");
        this.backtrack();
        return;
      }
      // console.log(selectItem)
      this.$emit("range", {
        type,
        item: row,
        index,
        list: _list,
        cards: _cards,
        cardList: this.cardList,
      });
    },
    handleChangeCardId(row, index, e) {
      if (row.card_name == "") {
        this.$set(this.cardList, index, {
          ...createDefaultItem(),
          shop_id: row.shop_id,
          shop_name: row.shop_name,
          trade_sn: row.trade_sn,
        });
        this.emitChange();
        return;
      }
      row.card_id = row.card_name;
      row.card_name = this.shopCardList.find(
        (item) => item.value === row.card_id
      ).label;
      this.$set(this.cardList, index, {
        ...row,
      });
      if (row.rowsapn[2] > 1) {
        for (let i = index + 1; i < this.cardList.length; i++) {
          const item = this.cardList[i];
          if (item.rowsapn[1] === 0) {
            this.cardList.splice(i, 1);
            i -= 1;
          } else break;
        }
      }
      this.$set(this.cardList, index, {
        ...createDefaultItem(),
        shop_id: row.shop_id,
        shop_name: row.shop_name,
        card_id: row.card_id,
        card_name: row.card_name,
        trade_sn: row.trade_sn,
      });
      this.emitChange();
      this.$emit("changeCardId", row, index);
    },
    handleChangeTradeSn(row, index) {
      if (row.trade_sn == "") {
        this.$set(this.cardList, index, {
          ...createDefaultItem(),
        });
        this.emitChange();
        return;
      }
      if (row.rowsapn[0] > 1) {
        for (let i = index + 1; i < this.cardList.length; i++) {
          const item = this.cardList[i];
          if (item.rowsapn[0] === 0) {
            this.cardList.splice(i, 1);
            i -= 1;
          } else break;
        }
      }
      this.$set(this.cardList, index, {
        ...createDefaultItem(),
        trade_sn: row.trade_sn,
      });
      this.emitChange();
    },
    handleChangeShopId(row, index) {
      if (row.shop_name == "") {
        this.$set(this.cardList, index, {
          ...createDefaultItem(),
          trade_sn: row.trade_sn,
        });
        this.emitChange();
        return;
      }
      row.shop_id = row.shop_name;
      row.shop_name = this.shopList.find(
        (item) => item.shop_id === row.shop_id
      ).shop_name;

      if (row.rowsapn[1] > 1) {
        for (let i = index + 1; i < this.cardList.length; i++) {
          const item = this.cardList[i];
          if (item.rowsapn[0] === 0) {
            this.cardList.splice(i, 1);
            i -= 1;
          } else break;
        }
      }
      this.$set(this.cardList, index, {
        ...createDefaultItem(),
        shop_id: row.shop_id,
        shop_name: row.shop_name,
        trade_sn: row.trade_sn,
      });
      this.emitChange();
    },
    handleTradeSnFocus() {
      const { close_sn } = this;
      if (this.tradeSnList.length == 0) {
        return this.$message.error("请输入正确的关联销售关卡单号");
      }
      if (!this.appType) {
        return this.$message.error("请先选择应用类型");
      }
    },
    handleShopFocus(trade_sn) {
      const { close_sn } = this;
      if (!close_sn) {
        return this.$message.error("请输入正确的关联销售关卡单号");
      }
      if (!trade_sn) {
        return this.$message.error("请选择关联销售单号");
      }
      API_saleCard.queryCloseCardsShop({
        close_sn,
        trade_sn,
      }).then((res) => {
        this.shopList = res;
      });
      // API_saleCard.getShopList({ mall_type: 2 }).then((res) => {
      //   this.shopList = res;
      // });
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value == "object" && Object.keys(value).length == 0) ||
        (typeof value == "string" && value.trim().length == 0)
      );
    },
    groupArrayByKey(arr = [], key) {
      return arr.reduce(
        (t, v) => (!t[v[key]] && (t[v[key]] = []), t[v[key]].push(v), t),
        {}
      );
    },
    getShopCardInfoList(cardList = this.cardList) {
      let shop_card_info_list = [];
      let _list = cardList.filter(
        (item) =>
          item.shop_id &&
          item.card_id &&
          item.card_code_start &&
          item.card_code_end
      );
      let _map = this.groupArrayByKey(_list, "shop_id");
      if (this.isEmpty(_map)) return [];
      for (const [key, value] of Object.entries(_map)) {
        const { shop_name, app_type } = value[0];
        shop_card_info_list.push({
          shop_id: key,
          shop_name,
          app_type,
          card_range_list: value,
        });
      }
      return shop_card_info_list;
    },
    checkCardName(obj) {
      return this.cardList.some((item) => item.card_id == obj.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-info {
  /deep/ .el-form-item__label {
    display: inline-block !important;
    float: none !important;
  }
  /deep/ .el-form-item__content {
    margin-left: 55px !important;
  }

  /deep/ .el-form-item__content {
    table {
      border: 1px solid #e6ecf7;
      tr > th {
        text-align: center;
        background-color: #e6ecf7;
        padding: 0;
      }
      tr > td {
        padding: 7px 3px;
        text-align: center;
        input {
          width: 100%;
        }
        .el-input__inner {
          padding: 0 5px;
        }
      }
      tr {
        .card-name,
        .card-section {
          min-width: auto !important;
        }
        .card-name {
          .warn-span {
            position: absolute;
            top: 80px;
            left: 100px;
            font-size: 14px;
            color: red;
          }
        }
      }
    }
  }
}

.td-switch-box {
  display: flex;
  align-items: center;
  .el-switch {
    margin-right: 5px;
  }
}

.card-section {
  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    /deep/ .el-input {
      width: 95px;
      &:first-of-type {
        margin-right: 3px !important;
      }
      &:last-of-type {
        margin-left: 3px !important;
      }
    }
  }
}
.flex-box {
  display: flex;
  align-items: center;
}
.ar-btn {
  margin-left: 2px;
  border: 0;
  padding: 0;
  font-size: 18px !important;
}
</style>
